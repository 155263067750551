import Vue from "vue";
import { useAttrs } from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async (to, from, next) => {
  const data = JSON.parse(localStorage.getItem("user"));
  if (data.user) {
    next();
  } else {
    next("/login");
  }
};

// routes.push({ path: '/', name: 'login', redirect: '/login' });

// routes.push({
//     path: '/dashboard',
//     component: () =>
//         import ('../pages/Global/Dashboard'),
//     beforeEnter: requireAuth
// });

routes.push({
  path: "/teste",
  component: () => import("../pages/teste"),
  beforeEnter: requireAuth,
});

//old
// routes.push({
//   path: "/dashboard",
//   component: () => import("../pages/Dash"),
//   beforeEnter: requireAuth,
// });

routes.push({
  path: "/dashboard/dre-result-analysis",
  component: () => import("../pages/DreResultAnalysis"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/dashboard",
  component: () => import("../pages/Dashboard"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/dashboard/:country",
  component: () => import("../pages/Global/Dashboard/country"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/dashboard/:code?",
  component: () => import("../pages/Dash"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/unity/:id/users",
  component: () => import("../pages/Global/Units/users"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/goal/:id",
  component: () => import("../pages/Global/Goals/view.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/goal/:id/ranking",
  component: () => import("../pages/Global/Goals/view_ranking.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/sales/:type/:id",
  component: () => import("../pages/Global/Sale/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/masters",
  component: () => import("../pages/Global/Master"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/countries",
  component: () => import("../pages/Global/Countries"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/Domains",
  component: () => import("../pages/Global/Domains"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/Patent",
  component: () => import("../pages/Global/Patent"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/Brand",
  component: () => import("../pages/Global/Brand"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/CompaniesTypes",
  component: () => import("../pages/Global/CompaniesTypes"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/Medias",
  component: () => import("../pages/Global/Medias"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/user/dash/:id",
  component: () => import("../pages/Global/Seller"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_profile/:id",
  component: () => import("../pages/Global/Seller/Pages/Profile"),
});

routes.push({
  path: "/my_earnings/:id",
  component: () => import("../pages/Global/Seller/Pages/Earnings"),
});

routes.push({
  path: "/my_sales/:id",
  component: () => import("../pages/Global/Seller/Pages/MySales"),
});

routes.push({
  path: "/my_clients/:id",
  component: () => import("../pages/Global/Seller/Pages/MyClients"),
});

routes.push({
  path: "/my_shopping/:id",
  component: () => import("../pages/Global/Seller/Pages/MyShopping"),
});

routes.push({
  path: "/ranking/:id",
  component: () => import("../pages/Global/Seller/Pages/Ranking"),
});

routes.push({
  path: "/my_goals/:id",
  component: () => import("../pages/Global/Seller/Pages/MyGoals"),
});

routes.push({
  path: "/my_awards/:id",
  component: () => import("../pages/Global/Seller/Pages/MyAwards"),
});

routes.push({
  path: "/my_catalog/:id",
  component: () => import("../pages/Global/Seller/Pages/MyCatalog"),
});

routes.push({
  path: "/my_talks/:id",
  component: () => import("../pages/Global/Seller/Pages/Talks"),
});

routes.push({
  path: "/my_talks2",
  component: () => import("../pages/Talks2"),
});

routes.push({
  path: "/messages/:id",
  component: () => import("../pages/Global/Seller/Pages/Message/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/posts/:id",
  component: () => import("../pages/Post/admin.vue"),
  beforeEnter: requireAuth,
});

//Academy

routes.push({
  path: "/academy/index",
  component: () => import("../pages/Academy/index.vue"),
});

routes.push({
  path: "/academy/module",
  component: () => import("../pages/Academy/Categories"),
});

routes.push({
  path: "/academy_categories",
  component: () => import("../pages/Academy/Categories"),
});

routes.push({
  path: "/academy_class",
  component: () => import("../pages/Academy/Class"),
});

routes.push({
  path: "/academy_class_file",
  component: () => import("../pages/Academy/Files"),
});

routes.push({
  path: "/academy_assessment",
  component: () => import("../pages/Academy/Assessments"),
});

routes.push({
  path: "/academy_assessment/:category",
  component: () => import("../pages/Academy/Assessments/category-class.vue"),
});

routes.push({
  path: "/academy_assessment/:category/:lesson",
  component: () => import("../pages/Academy/Assessments/class-assessment.vue"),
});

routes.push({
  path: "/my_academy",
  component: () => import("../pages/Academy/view"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_academy/:category",
  component: () => import("../pages/Academy/view/category.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_academy/:category/:lesson",
  component: () => import("../pages/Academy/view/lesson.vue"),
  beforeEnter: requireAuth,
});

// routes.push({
//     path: "/my_academy",
//     component: () =>
//         import ("../pages/Seller/Academy/"),
//     beforeEnter: requireAuth,
// });

// routes.push({
//     path: "/my_academy/:category",
//     component: () =>
//         import ("../pages/Seller/Academy/category.vue"),
//     beforeEnter: requireAuth,
// });

// routes.push({
//     path: "/my_academy/:category/:lesson",
//     component: () =>
//         import ("../pages/Seller/Academy/lesson.vue"),
//     beforeEnter: requireAuth,
// });

routes.push({
  path: "/scale/:id",
  component: () => import("../pages/Global/Seller/Pages/Scale/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/cash_flow/:id",
  component: () => import("../pages/Global/CashFlow/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_email/:id",
  component: () => import("../pages/Global/Seller/Pages/MyEmail"),
});

routes.push({
  path: "/companies",
  component: () => import("../pages/Companies"),
});

routes.push({
  path: "/categories",
  component: () => import("../pages/Global/Categories"),
});

routes.push({
  path: "/departments",
  component: () => import("../pages/Global/Departments"),
});

routes.push({
  path: "/functions",
  component: () => import("../pages/Global/Functions"),
});

routes.push({
  path: "/levels",
  component: () => import("../pages/Global/Levels"),
});

routes.push({
  path: "/testes",
  component: () => import("../pages/Testes"),
  beforeEnter: requireAuth,
});

//Plano de Expanção
routes.push({
  path: "/expansion_plan",
  component: () => import("../pages/ExpansionPlan/dash"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/modules",
  component: () => import("../pages/Global/Modules"),
});

routes.push({
  path: "/permissions",
  component: () => import("../pages/Global/Permissions"),
});

routes.push({
  path: "/segments",
  component: () => import("../pages/Global/ProductsAttributes/segments.vue"),
});

routes.push({
  path: "/collections",
  component: () => import("../pages/Global/ProductsAttributes/collections.vue"),
});
routes.push({
  path: "/sectors",
  component: () => import("../pages/Global/ProductsAttributes/sectors.vue"),
});

routes.push({
  path: "/grids",
  component: () => import("../pages/Global/ProductsAttributes/grids.vue"),
});

routes.push({
  path: "/colors",
  component: () => import("../pages/Global/ProductsAttributes/colors.vue"),
});

routes.push({
  path: "/groups",
  component: () => import("../pages/Global/ProductsAttributes/groups.vue"),
});

routes.push({
  path: "/subgroups",
  component: () => import("../pages/Global/ProductsAttributes/subgroups.vue"),
});

routes.push({
  path: "/thickness",
  component: () => import("../pages/Global/ProductsAttributes/thickness.vue"),
});

routes.push({
  path: "/finishing",
  component: () => import("../pages/Global/ProductsAttributes/finishing.vue"),
});

routes.push({
  path: "/lines",
  component: () => import("../pages/Global/ProductsAttributes/lines.vue"),
});

routes.push({
  path: "/sales_channel",
  component: () =>
    import("../pages/Global/ProductsAttributes/sales_channel.vue"),
});

routes.push({
  path: "/product_categories",
  component: () => import("../pages/Global/ProductsAttributes/categories.vue"),
});

routes.push({
  path: "/material",
  component: () => import("../pages/Global/ProductsAttributes/material.vue"),
});

routes.push({
  path: "/genders",
  component: () => import("../pages/Global/ProductsAttributes/genders.vue"),
});
routes.push({
  path: "/measurement",
  component: () => import("../pages/Global/ProductsAttributes/measurement.vue"),
});
routes.push({
  path: "/destiny",
  component: () => import("../pages/Global/ProductsAttributes/destiny.vue"),
});

routes.push({
  path: "/guarantees",
  component: () => import("../pages/Guarantees/index.vue"),
});

routes.push({
  path: "/representativeness",
  component: () => import("../pages/Representativeness/index.vue"),
});

routes.push({
  path: "/markups",
  component: () => import("../pages/Markup/index.vue"),
});

routes.push({
  path: "/price_readjust",
  component: () => import("../pages/PriceReadjust/index.vue"),
});

routes.push({
  path: "/classes",
  component: () => import("../pages/Classes/index.vue"),
});

routes.push({
  path: "/products",
  component: () => import("../pages/Global/Products"),
});

routes.push({
  path: "/product/:product_id/:code/languages",
  component: () => import("../pages/Global/Products/language.vue"),
});

routes.push({
  path: "/product/:product_id/:code/prices",
  component: () => import("../pages/Global/Products/prices.vue"),
});

routes.push({
  path: "/languages",
  component: () => import("../pages/Global/Language"),
});

routes.push({
  path: "/users",
  component: () => import("../pages/Global/Users"),
});

routes.push({
  path: "/tasks",
  component: () => import("../pages/Tasks"),
});

routes.push({
  path: "/academy_categories",
  component: () => import("../pages/Academy/Categories"),
});

routes.push({
  path: "/academy_class",
  component: () => import("../pages/Academy/Class"),
});

routes.push({
  path: "/academy_class_file",
  component: () => import("../pages/Academy/Files"),
});

routes.push({
  path: "/my_talks",
  component: () => import("../pages/Admin/Talks"),
});

routes.push({
  path: "/posts",
  component: () => import("../pages/Post/admin.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/master_franchises",
  component: () => import("../pages/MasterFranchise/index.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/type_ventures",
  component: () => import("../pages/TypeVentures"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/type_payment",
  component: () => import("../pages/TypePayment"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/models_business",
  component: () => import("../pages/ModelsBusiness"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/my_expansion_plan/:country_id?",
  component: () => import("../pages/ExpansionPlan"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/goals",
  component: () => import("../pages/Global/Goals/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/awards",
  component: () => import("../pages/Global/Award/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/bombs",
  component: () => import("../pages/Global/Bombs/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/rankings",
  component: () => import("../pages/Rankings/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/best_seller",
  component: () => import("../pages/Best_seller/"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/birthdays",
  component: () => import("../pages/Birthdays/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalogs",
  component: () => import("../pages/Catalogs/"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/:id/:name",
  component: () => import("../pages/Catalogs/list"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/:id/category/:category",
  component: () => import("../pages/Catalogs/list"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/catalog/module/:id/:name",
  component: () => import("../pages/Catalogs/list_module"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/product/:product_id/:name",
  component: () => import("../pages/Catalogs/view"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/products/list",
  component: () => import("../pages/Products"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/chat",
  component: () => import("../pages/Chat"),
  beforeEnter: requireAuth,
});

//TODO
routes.push({
  path: "/tasks",
  component: () => import("../pages/Tasks"),
});

routes.push({
  path: "/task/:id",
  component: () => import("../pages/Tasks/viewTask.vue"),
});

routes.push({
  path: "/products/dashbord",
  component: () => import("../pages/ProductsDash"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/products/dashbord/view/:id",
  component: () => import("../pages/ProductsDash/view"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/volumes/:type/:local_id",
  component: () =>
      import ("../pages/Volumes"),
});

routes.push({
  path: "/banners/catalogs",
  component: () => import("../pages/Banners"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/buy/dash",
  component: () => import("../pages/Buy/dash.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/buy/product",
  component: () => import("../pages/Buy/product-dash.vue"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/requests",
  component: () => import("../pages/Orders/requests"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/orders/view/:id",
  component: () => import("../pages/Orders/view"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/orders/items/:id",
  component: () => import("../pages/Orders/items"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/unity/:id/users",
  component: () => import("../pages/Units/users"),
  beforeEnter: requireAuth,
});


routes.push({
  path: "/unity/:id/scale",
  component: () => import("../pages/Admin/Scale"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/volumes/:type/:local_id",
  component: () =>
      import ("../pages/Volumes"),
});

routes.push({
  path: "/profiles",
  component: () => import("../pages/Profiles"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/approved-suppliers",
  component: () => import("../pages/ApprovedSupplier"),
  beforeEnter: requireAuth,
});

Vue.use(Router);
export default new Router({
  mode: "history",
  routes,
});
