import Vue from "vue";
import { useAttrs } from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data.user) {
        next();
    } else {
        next("/login");
    }
};




routes.push({
    path: "/posts",
    component: () =>
        import ("../pages/Post/admin.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/dashboard",
    component: () =>
        import ("../pages/Stopper/Dashboard"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/indications",
    component: () =>
        import ("../pages/Indications"),
    beforeEnter: requireAuth,
});




routes.push({
    path: "/my_profile",
    component: () =>
        import ("../pages/Seller/Profile"),
});

routes.push({
    path: "/my_earnings",
    component: () =>
        import ("../pages/Seller/Earnings"),
});

routes.push({
    path: "/my_clients",
    component: () =>
        import ("../pages/Seller/MyClients"),
});

routes.push({
    path: "/my_shopping",
    component: () =>
        import ("../pages/Seller/MyShopping"),
});

routes.push({
    path: "/my_sales",
    component: () =>
        import ("../pages/Seller/MySales"),
});



routes.push({
    path: "/ranking",
    component: () =>
        import ("../pages/Seller/Ranking"),
});

routes.push({
    path: "/my_goals",
    component: () =>
        import ("../pages/Seller/MyGoals"),
});

routes.push({
    path: "/my_awards",
    component: () =>
        import ("../pages/Seller/MyAwards"),
});

routes.push({
    path: "/my_awards/2",
    component: () =>
        import ("../pages/Seller/MyAwards/page2.vue"),
});

routes.push({
    path: "/my_catalog",
    component: () =>
        import ("../pages/Seller/MyCatalog"),
});

routes.push({
    path: '/talks',
    component: () =>
        import ('../pages/Talks2')
});

routes.push({
    path: "/my_email",
    component: () =>
        import ("../pages/Seller/MyEmail"),
});

routes.push({
    path: "/my_talks",
    component: () =>
        import ("../pages/Seller/Talks"),
});

routes.push({
    path: "/my_talks2",
    component: () =>
        import ("../pages/Talks2"),
});

routes.push({
    path: "/goals",
    component: () =>
        import ("../pages/Seller/Goals/"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/goal/:id",
    component: () =>
        import ("../pages/Seller/Goals/user.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/goal/:id/award",
    component: () =>
        import ("../pages/Seller/Award/"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/goal/:id/ranking",
    component: () =>
        import ("../pages/Seller/Goals/view_ranking.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/messages",
    component: () =>
        import ("../pages/Seller/Message/"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/sales",
    component: () =>
        import ("../pages/Seller/Sale/"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/my_academy",
    component: () =>
        import ("../pages/Academy/view"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/my_academy/:category",
    component: () =>
        import ("../pages/Academy/view/category.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/my_academy/:category/:lesson",
    component: () =>
        import ("../pages/Academy/view/lesson.vue"),
    beforeEnter: requireAuth,
});

// routes.push({
//     path: "/my_academy",
//     component: () =>
//         import ("../pages/Seller/Academy/"),
//     beforeEnter: requireAuth,
// });

// routes.push({
//     path: "/my_academy/:category",
//     component: () =>
//         import ("../pages/Seller/Academy/category.vue"),
//     beforeEnter: requireAuth,
// });

// routes.push({
//     path: "/my_academy/:category/:lesson",
//     component: () =>
//         import ("../pages/Seller/Academy/lesson.vue"),
//     beforeEnter: requireAuth,
// });

routes.push({
    path: "/cash_flow",
    component: () =>
        import ("../pages/Seller/CashFlow/"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/cash_flow/list",
    component: () =>
        import ("../pages/Seller/CashFlow/list.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/testes",
    component: () =>
        import ("../pages/Testes"),
    beforeEnter: requireAuth,
});

//TODO
routes.push({
    path: "/tasks",
    component: () =>
        import ("../pages/Tasks"),
});

routes.push({
    path: "/task/:id",
    component: () =>
        import ("../pages/Tasks/viewTask.vue"),
});

Vue.use(Router);
export default new Router({
    mode: "history",
    routes,
});