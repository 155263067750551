import Vue from "vue";
import { useAttrs } from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data.user) {
        next();
    } else {
        next("/login");
    }
};

routes.push({
    path: "/dashboard",
    component: () =>
        import ("../pages/Developer"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/translate",
    component: () =>
        import ("../pages/Translate"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/modules",
    component: () =>
        import ("../pages/Modules"),
    beforeEnter: requireAuth,
});


Vue.use(Router);
export default new Router({
    mode: "history",
    routes,
});