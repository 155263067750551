import Vue from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async (to, from, next) => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data.user) {
        next();
    } else {
        next("/login");
    }
};

routes.push({
    path: "/dashboard",
    component: () => import("../pages/Orders/requests"),
    beforeEnter: requireAuth,
  });
  

routes.push({
    path: "/catalogs",
    component: () => import("../pages/Catalogs/"),
    beforeEnter: requireAuth,
  });
  
  routes.push({
    path: "/catalog/:id/:name",
    component: () => import("../pages/Catalogs/list"),
    beforeEnter: requireAuth,
  });
  
  routes.push({
    path: "/catalog/:id/category/:category",
    component: () => import("../pages/Catalogs/list"),
    beforeEnter: requireAuth,
  });
  
  routes.push({
    path: "/catalog/module/:id/:name",
    component: () => import("../pages/Catalogs/list_module"),
    beforeEnter: requireAuth,
  });

  routes.push({
    path: "/banners/catalogs",
    component: () => import("../pages/Banners"),
    beforeEnter: requireAuth,
  });
  
  routes.push({
    path: "/product/:product_id/:name",
    component: () => import("../pages/Catalogs/view"),
    beforeEnter: requireAuth,
  });
  
  routes.push({
    path: "/products/list",
    component: () => import("../pages/Products"),
    beforeEnter: requireAuth,
  });
  

  
  routes.push({
    path: "/products/dashbord",
    component: () => import("../pages/ProductsDash"),
    beforeEnter: requireAuth,
  });
  routes.push({
    path: "/products/dashbord/view/:id",
    component: () => import("../pages/ProductsDash/view"),
    beforeEnter: requireAuth,
  });
  

routes.push({
    path: "/banners/catalogs",
    component: () => import("../pages/Banners"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/requests",
    component: () => import("../pages/Orders/requests"),
    beforeEnter: requireAuth,
  });
  

Vue.use(Router);
export default new Router({
    mode: "history",
    routes,
});