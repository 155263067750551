export const FormatMoney = (v) => {
  let val = (v / 1).toFixed(2).replace(".", ",");
  return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const removeSpaceString = (string) => {
  return string.replace(/\s/g, "-");
};

export const numberFormater = (
  value,
  style = "decimal",
  currency = undefined,
  unit = undefined,
  unitDisplay = undefined
) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style,
    currency: currency,
    unit: unit,
    unitDisplay: unitDisplay,
  });

  return formatter.format(value);
};
