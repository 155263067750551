import Vue from 'vue';
import { useAttrs } from 'vue';
import Router from 'vue-router';

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem('user'));
    if (data.user) {
        next();
    } else {
        next('/login');
    }
};
routes.push({
    path: '/dashboard',
    component: () =>
        import ('../pages/Dealer/Dashboard'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/sales',
    component: () =>
        import ('../pages/Dealer/Sale/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goals',
    component: () =>
        import ('../pages/Dealer/Goals/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id',
    component: () =>
        import ('../pages/Dealer/Goals/user.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id/award',
    component: () =>
        import ('../pages/Dealer/Award/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id/ranking',
    component: () =>
        import ('../pages/Dealer/Goals/view_ranking.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/call',
    component: () =>
        import ('../pages/Dealer/Call/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/messages',
    component: () =>
        import ('../pages/Dealer/Message/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/talks',
    component: () =>
        import ('../pages/Talks2')
});

routes.push({
    path: '/testes',
    component: () =>
        import ('../pages/Testes'),
    beforeEnter: requireAuth
});

//TODO
routes.push({
    path: "/tasks",
    component: () =>
        import ("../pages/Tasks"),
});

routes.push({
    path: "/task/:id",
    component: () =>
        import ("../pages/Tasks/viewTask.vue"),
});



Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});